import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import hissesorgula from "../../Assets/Projects/hissesorgula.png";
import kombin from "../../Assets/Projects/kombin.png";
import scope from "../../Assets/Projects/scope.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Son <strong className="purple">Çalışmalarım </strong>
        </h1>
        <p style={{ color: "white" }}>
          Son zamanlarda üzerinde çalıştığım birkaç proje
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={kombin}
              title="AI İLE KOMBİN TAMAMLAMA"
              description="Kullanıcıların kombinlerindeki eksik parçaları için web scraping, makina öğrenimi ve görüntü işleme teknikleri kullanılarak kullanıcıya mevcut kıyafetiyle uyumlu istediği parçayı önerilerde bulunan flutter ile bir mobil uygulama geliştirildi. Webscraping yöntemi ile toplanan kıyafet verileriyle veri seti oluşturuldu bu veri setiyle model eğitildi. Uygulama açık kaynak kodlu değildir."
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={scope}
              title="Scope Endo "
              description="Scope Endo Bursa Şubesi için müşterilerin iletişimini kolaylaştırmak amacıyla bir ön sipariş sayfası tasarlandı. Kullanıcılar telefon veya e-posta yöntemiyle iletişime geçmek yerine sistem üzerinden ihtiyaç duydukları ürünleri sisteme giriyorlar ve bursa temsilcisi kullanıcıya özel bir teklif hazırlayıp geri dönüş yapıyor. Ayrıca sistem üzerinden kullanıcılar mevcut ürünleri görüntüleyip inceleme fırsatı buluyor."
              isBlog={false}
              ghLink="https://github.com/elifgider/scopeendo"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hissesorgula}
              title="Hisse Sorgula"
              description="Freelancer olarak geliştirdiğim bu proje, kullanıcıların tüm şirket hisselerini görüntülemesini sağlıyor. Ayrıca, bu hisselerin Katılım Endeksi, Temettü gibi değerlerini canlı olarak takip edebilir ve hisse durum grafikleri üzerinden piyasa takibi yapabilirler. Proje, responsive bir şekilde geliştirilmiş olup mobil cihazlar için de kullanılabilir. "
              demoLink="https://hissesorgula.com"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
